<template>
    <div>
        <div class="row mb-5">
            <div class="col-12">
                <h1 class="mt-5 mb-0 text-center caligraphy">Cocktails</h1>
                <!-- <h3 class="text-center mb-4">14</h3> -->
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
                <h3 class="mb-0 caligraphy text-center">Athena</h3>
                <p class="text-center mb-3"><b>Blueberry, Pineapple, Tequila, Lime</b></p>

                <h3 class="mb-0 caligraphy text-center">Kosmo</h3>
                <p class="text-center mb-3"><b>Vodka, White Cranberry, Orange, Lime</b></p>

                <h3 class="mb-0 caligraphy text-center">Greek Fashion</h3>
                <p class="text-center mb-3"><b>Metaxa, Orange, Chocolate Bitters</b></p>

                <h3 class="mb-0 caligraphy text-center">Persephone's Heart</h3>
                <p class="text-center mb-3"><b>Mezcal, Pomegranate, Honey/Rosemary, Lime</b></p>

                <h3 class="mb-0 caligraphy text-center">Karpouzi</h3>
                <p class="text-center mb-3"><b>Gin, Aperol, Watermelon, Lime</b></p>

                <h3 class="mb-0 caligraphy text-center">Hesperis</h3>
                <p class="text-center mb-3"><b>Bourbon, Orange Marmalade, Lemon, Tonic</b></p>

                <h3 class="mb-0 caligraphy text-center">Sangria</h3>
                <p class="text-center mb-3"><b>RED - WHITE - PINK - SPARKLING</b></p>
            </div>
            <div class="col-lg-2"></div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'WineList'
}
</script>