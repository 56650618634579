
<template>
    <div class="container-fluid">
        <div class="row border-top">
            <div class="col-12">
                <h5 class="mb-3 text-center caligraphy mt-2">Dinner Menu Offered all day starting at noon in the weekend!</h5>
                <h1 class="mt-5 mb-0 text-center caligraphy">Mezedes</h1>
                <h3 class="mb-3 text-center caligraphy">Appetizers</h3>
            </div>
            <div class="col-lg-2"></div>
            <div class="col-lg-4 mt-3">
                <h3 class="mb-3 text-center caligraphy">· HOT ·</h3>
                <p class="text-center"><b>Saganaki</b><br>
                    Pan Fried Vlahotyri, Strawberry and Tomato Jam</p>

                <p class="text-center"><b>Loukaniko</b><br>
                    Greek Grilled Sausage, Honey Mustarda</p>

                <p class="text-center"><b>Spanakopita</b><br>
                    Crispy Phyllo, Spinach, Feta Cheese, Herbs, Lemon Yogurt</p>
                
                <p class="text-center"><b>Kolokithakia</b><br>
                    Lightly Battered Zucchini, served with Tzatziki sauce</p>

                <p class="text-center"><b>Dolmades</b><br>
                    Stuffed Grapeleaves, Ground Beef, Rice, Herbs, Lemon Yogurt</p>

                <p class="text-center"><b>Feta Sousami</b><br>
                    Sesame, Encrasted, Honey Drizle</p>

                <p class="text-center"><b>Kataifi Shrimp</b><br>
                    Lightly Fried, Sweet and Spicy Honey</p>

                <p class="text-center"><b>Oktapodi</b><br>
                    Grilled Octopus, Onions, Santorini Split Pea Purée, Fennel, Herbs, Boukovo</p>

                <p class="text-center"><b>Kalamarakia</b><br>
                    Fried Calamari, Pickle Pepper, Fennel, Lemon Garlic Aioli</p>

                <p class="text-center"><b>Keftedes</b><br>
                    Lamb Meatballs, Spicy Tomato, Feta Cheese</p>
                
                <p class="text-center"><b>Manti me Kima</b><br>
                    Greek Dumplings, Bolognese Stuffing, Beef Mavrodaphne Broth, Truffle Oil</p>
            </div>

            <div class="col-lg-4 mt-3">
                <h3 class="mb-3 text-center caligraphy">· COLD ·</h3>
                <p class="text-center"><b>Elies</b><br>
                    Mix Greek Olives, Garlic, Citrus Zest, Chili</p>

                <p class="text-center"><b>Pantzaria</b><br>
                    Roasted Beets, Spring Mix, Candled Walnuts, Manouri Cheese, Petimezi, Vinaigrette, Koulouri</p>

                <p class="text-center"><b>Horiatiki Salad</b><br>
                    Tomato, Cucumber, Onions, Olives, Feta Cheese, Peppers, Capers, Greek Extra Virgin Olive Oil</p>
                
                <p class="text-center"><b>Greek Caesar Salad</b><br>
                    Romain, Kale, Kefalograviera Cheese, Pita Croutons, Caesar Dressing</p>

                <p class="text-center"><b>Trio of Dips</b><br>
                    Served with Grilled Pita Bread <br>
                    Tzatziki: Cucumber, Dill, Garlic, Yogur<br>
                    Whipped Feta: Chili Peppers, Olive Oil, Feta Cheese<br>
                    Hummus: Chickpeas, Tahini, Garlic, Lemon Juice, EVOO<br>
                </p>

                <p class="text-center"><b>Piperia Gemisti</b><br>
                    Stuffed Florina Pepper, Spicy Feta, Herb Yogurt, Pickled Raisins</p>

                <p class="text-center"><b>Kavourosalata</b><br>
                    Lump Crab Salad, Dill Aioli, Avocado Tzatziki, Crispy Sesame Phyllo</p>
            </div>
            <div class="col-lg-2"></div>
        </div>

        <div class="row ">
            <div class="col-12">
                <h1 class="mt-5 mb-0 text-center caligraphy">Deipno</h1>
                <h3 class="mb-3 text-center caligraphy">Dinner</h3>
            </div>
            <div class="col-lg-2"></div>
            <div class="col-lg-4 mt-3">
                <h3 class="mb-3 text-center caligraphy">· Meat & Fish ·</h3>
                <p class="text-center"><b>Papoutsakia</b><br>
                    Baked Eggplant Halves, Spiced Meat Filling, Cheesy Bechamel, Mixed Greens</p>

                <p class="text-center"><b>Kotopoulo</b><br>
                    Chicken Chops, Chimichuri, Avocado Tzatziki</p>

                <p class="text-center"><b>Brizola</b><br>
                    30 Day Aged Boneless Ribeye, Roasted Baby Potatoes, Shishito Peppers, Rigani Sauce</p>
                
                <p class="text-center"><b>Lavraki</b><br>
                    Pan Seared Branzino, Chickpea Revithada, Pistachio Skordalia, Broccolini</p>

                <p class="text-center"><b>Astakomakaronada</b><br>
                    Lobster, Corn, Cherry Tomatoes, Lemon Yogurt Buerre Blanc, Hilopites Pasta</p>

                <p class="text-center"><b>Lamb Chops</b><br>
                    Fries, Feta Cheese, Tzatziki Sauce</p>

                <p class="text-center"><b>Kontosouvli</b><br>
                    Large Pork Skewer, Tzatziki, Pita Bread, Greek Fries</p>
                
                <p class="text-center"><b>Solomos</b><br>
                    Pan Seared Salmon, Spanakorizo, Florina Pepper Romesco Sauce</p>
            </div>
            <div class="col-lg-4 mt-3">
                <h3 class="mb-2 text-center caligraphy">· To Share ·</h3>
                <p class="text-center"><b>Poikilia Kreaton* (Serves 2)</b><br>
                    Selection of Grilled Meats, Kontosouvli, Lamb Chops, Grilled Chicken, Loukaniko served with Fries, Pita Bread, Tzatziki, Whipped Feta</p>

                <p class="text-center"><b>Giouvetsi Thalassinon (Serves 2)</b><br>
                    Mussels, Calamari, Shrimp, Lobster, Orzo Pasta, Tomatoe Broth, Mizithra Cheese</p>

                <h3 class="mt-5 mb-2 text-center caligraphy">· Synodeftika - Sides ·</h3>
                <p class="text-center"><b>Broccolini</b><br>
                    Braised, Olive Oil, Lemon Juice</p>
                
                <p class="text-center"><b>Spanakorizo</b><br>
                    Rice, Spinach, Lemon</p>

                <p class="text-center"><b>Pita Bread</b><br>
                    Grilled, Oregano, Olive Oil</p>

                <p class="text-center"><b>Greek Fries</b><br>
                    Feta, Oregano, Salt, Pepper</p>

                <p class="text-center"><b>Roasted Potatoes</b><br></p>
            </div>
            <div class="col-lg-2"></div>
        </div>

        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 mt-5">
                <h1 class="mb-2 text-center caligraphy">· Epidorpio ·</h1>
                <h3 class="mb-3 text-center caligraphy">Dessert</h3>
                <p class="text-center"><b>Bougatsa (Serves 2)</b><br>
                    Traditional Crispy Phyllo Dough, Semolina Custard, Merenda Chocolate Sauce</p>

                <p class="text-center"><b>Lemonopita</b><br>
                    Merengue, Lemon Cream, Graham Crumb</p>

                <p class="text-center"><b>Saragli</b><br>
                    Rolled Baklava, Olive Oil Ice Cream, Sea Salt</p>
            </div>

            <div class="col-lg-2"></div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'DinnerMenu'
}
</script>